import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CareerEssential, GoalStatus, LongGoal, ShortGoal } from '../types/goals';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoalsService {
  constructor(private http: HttpClient) {}

  getGoalStatuses(): Observable<GoalStatus[]> {
    return this.http.get<GoalStatus[]>(environment.apiUrl + `/goal-statuses/`);
  }

  getShortGoals(): Observable<ShortGoal[]> {
    return this.http.get<ShortGoal[]>(environment.apiUrl + `/short-goals/`);
  }

  createShortGoal(shortGoal: ShortGoal): Observable<ShortGoal> {
    return this.http.post<ShortGoal>(environment.apiUrl + `/short-goals/`, shortGoal);
  }

  updateShortGoal(shortGoalId: number, shortGoal: Partial<ShortGoal>): Observable<ShortGoal> {
    return this.http.patch<ShortGoal>(`${environment.apiUrl}/short-goals/${shortGoalId}/`, shortGoal);
  }

  deleteShortGoal(shortGoalId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/short-goals/${shortGoalId}/`);
  }

  getLongGoals(): Observable<LongGoal[]> {
    return this.http.get<LongGoal[]>(environment.apiUrl + `/long-goals/`);
  }

  createLongGoal(longGoal: LongGoal): Observable<LongGoal> {
    return this.http.post<LongGoal>(environment.apiUrl + `/long-goals/`, longGoal);
  }

  updateLongGoal(longGoalId: number, longGoal: Partial<LongGoal>): Observable<LongGoal> {
    return this.http.patch<LongGoal>(`${environment.apiUrl}/long-goals/${longGoalId}/`, longGoal);
  }

  getCareerEssentials(): Observable<CareerEssential[]> {
    return this.http.get<CareerEssential[]>(environment.apiUrl + `/career-essentials/`);
  }

  createCareerEssential(careerEssential: Partial<CareerEssential>): Observable<CareerEssential> {
    return this.http.post<CareerEssential>(environment.apiUrl + `/career-essentials/`, careerEssential);
  }

  updateCareerEssential(careerEssentialId: number, careerEssential: Partial<CareerEssential>): Observable<CareerEssential> {
    return this.http.patch<CareerEssential>(`${environment.apiUrl}/career-essentials/${careerEssentialId}/`, careerEssential);
  }
}
